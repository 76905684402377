import { useMutation } from '@tanstack/react-query';
import { Form, Input, message, Modal, Tooltip } from 'antd';
import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import LabelsFormList, {
  convertFromFormList,
} from 'components/common/LabelsFormList';
import useOrganizationIds from 'hooks/organizations/useOrganizationIds';
import { Organization } from 'interfaces/organization';
import { BaseError } from 'utlis/BaseError';
import getErrorMessage from 'utlis/getErrorMessage';

interface Props {
  organization?: Organization;
  onClose: () => void;
  onCreated?: (organization: Organization) => void;
  onEdited?: () => void;
  open: boolean;
}

interface FormData {
  id: string;
  name: string;
  metadata: {
    key: string;
    value: string;
  }[];
}

const OrganizationModal: React.FC<Props> = ({
  organization,
  onClose,
  open,
  onCreated,
  onEdited,
}) => {
  const { postOrganization, putOrganization } = useAdminOrganizationApi();
  const [form] = Form.useForm<FormData>();

  const organizations = useOrganizationIds();

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: async (data: FormData) => {
      const metadata = convertFromFormList(data.metadata);

      if (organization) {
        await putOrganization({
          ...data,
          metadata,
        });
      } else {
        if (organizations.includes(data.id)) {
          throw new BaseError(
            'Organization already exists',
            'ORGANIZATION_EXISTS',
          );
        }

        return await postOrganization({ ...data, metadata });
      }
    },
    onSuccess: (data) => {
      onClose();
      if (data !== undefined) {
        onCreated?.(data);
      } else {
        onEdited?.();
      }
      form.resetFields();
      message.success(
        organization
          ? 'Organization updated successfully'
          : 'Organization created successfully',
      );
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    },
  });

  const transformFromOrganization = (org: Organization) => {
    return {
      ...org,
      metadata: Object.entries(org.metadata).map(([key, value]) => ({
        key,
        value,
      })),
    };
  };

  return (
    <Modal
      title={
        organization
          ? `Edit Organization ${organization.id}`
          : 'Create Organization'
      }
      open={open} // TODO this causes initialValues to not update when model is opened, because it is already mounted and stores the keeps the old values
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={isPending}
      okText={organization ? 'Save' : 'Create'}
      width={800}
    >
      <Form<FormData>
        form={form}
        onFinish={onSubmit}
        initialValues={
          organization ? transformFromOrganization(organization) : undefined
        }
      >
        <Tooltip
          title={organization ? 'ID cannot be changed' : null}
          mouseEnterDelay={0.5}
        >
          <Form.Item label="ID" name="id">
            <Input disabled={!!organization} />
          </Form.Item>
        </Tooltip>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Metadata">
          <LabelsFormList name="metadata" label="Metadata" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrganizationModal;

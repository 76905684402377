export interface FilterOptions {
  sources: string[];
  organizations: string[];
  industries: string[];
}

export interface FilterSettings {
  selectedIndustries: string[];
  selectedSources: string[];
  selectedOrganizations: string[];
  showOnlyActive: boolean;
  hideDevOrganizations: boolean;
}

export interface FilterSettingsHandlers {
  setSelectedSources: (sources: string[]) => void;
  setSelectedOrganizations: (orgs: string[]) => void;
  setSelectedIndustries: (industries: string[]) => void;
  setShowOnlyActive: (active: boolean) => void;
  setHideDevOrganizations: (hide: boolean) => void;
}

export const DEV_ORGANIZATIONS = ['dev', 'voize', 'voize-demo'];

export const downloadCSV = (
  property: string,
  distribution: Record<string, number>,
) => {
  const total = Object.values(distribution).reduce((a, b) => a + b, 0);
  const rows = Object.entries(distribution).map(([name, value]) => {
    const percentage = ((value / total) * 100).toFixed(1);
    return `${name},${value},${percentage}%`;
  });

  const csvContent = `Name,Count,Percentage\n${rows.join('\n')}`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${property}-statistics.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const renderFilterSettings = (
  filterSettings: FilterSettings,
): string => {
  const settings = [];
  if (filterSettings.selectedIndustries.length > 0) {
    settings.push(`${filterSettings.selectedIndustries.join(', ')}`);
  }
  if (filterSettings.selectedSources.length > 0) {
    settings.push(`${filterSettings.selectedSources.join(', ')}`);
  }
  if (filterSettings.selectedOrganizations.length > 0) {
    settings.push(`${filterSettings.selectedOrganizations.join(', ')}`);
  }
  if (filterSettings.hideDevOrganizations) {
    settings.push('ohne Entwicklerorganisationen');
  }
  if (filterSettings.showOnlyActive) {
    settings.push('aktiv in den letzten 30 Tagen');
  }
  return settings.join(', ');
};

import { message } from 'antd';
import { LuCopy } from 'react-icons/lu';

interface Props {
  id: string;
  name?: string;
  showFull?: boolean;
}

const CopyableId: React.FC<Props> = ({ id, name = 'ID', showFull = false }) => {
  return (
    <div
      className="group relative cursor-pointer z-10"
      onClick={() => {
        navigator.clipboard.writeText(id);
        message.success(`${name} wurde in die Zwischenablage kopiert!`);
      }}
    >
      <div className="font-mono">{showFull ? id : id.split('-')[0]}</div>
      <div className="pointer-events-none absolute top-0 -mx-2 -my-1 flex items-center gap-2 whitespace-nowrap rounded-sm bg-white px-2 py-1 font-mono opacity-0 shadow-xl transition-opacity duration-300 ease-in-out hover:pointer-events-auto group-hover:opacity-100">
        <span>{id}</span>
        <LuCopy className="text-gray-600" />
      </div>
    </div>
  );
};

export default CopyableId;

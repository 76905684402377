import * as Sentry from '@sentry/react';
import {
  createRootRoute,
  createRoute,
  createRouter,
  Outlet,
  useParams,
} from '@tanstack/react-router';
import DeviceView from 'components/device/DeviceView';
import AdminView from 'components/scaffold/AdminView';
import OrganizationView from 'components/scaffold/OrganizationView';
import ScaffoldWithAuth from 'components/scaffold/ScaffoldWithAuth';
import SeniorCareDeviceConfigsView from 'components/seniorcare/SeniorCareDeviceConfigView';
import SeniorCareIntegrationConfigsView from 'components/seniorcare/SeniorCareIntegrationConfigsView';
import AdminDeviceActivationView from 'components/views/AdminDeviceActivationView';
import AdminDevicesView from 'components/views/AdminDevicesView';
import AdminGlobalDeviceConfigurationCreateView from 'components/views/AdminGlobalDeviceConfigurationCreateView';
import AdminGlobalDeviceConfigurationEditView from 'components/views/AdminGlobalDeviceConfigurationEditView';
import AdminGlobalDeviceConfigurationView from 'components/views/AdminGlobalDeviceConfigurationView';
import AdminOrganizationDeviceConfigurationCreateView from 'components/views/AdminOrganizationDeviceConfigurationCreateView';
import AdminOrganizationDeviceConfigurationEditView from 'components/views/AdminOrganizationDeviceConfigurationEditView';
import AdminOrganizationDeviceConfigurationView from 'components/views/AdminOrganizationDeviceConfigurationView';
import AdminSeniorCareUsersView from 'components/views/AdminSeniorCareUsersView';
import ContractsView from 'components/views/ContractsView';
import HomeView from 'components/views/HomeView';
import ManagementDeviceConfigurationCreateView from 'components/views/management/configuration/ManagementDeviceConfigurationCreateView';
import ManagementOrganizationDeviceConfigurationEditView from 'components/views/management/configuration/ManagementDeviceConfigurationEditView';
import ManagementDeviceConfigurationsView from 'components/views/management/configuration/ManagementDeviceConfigurationsView';
import ManagementDeviceActivationView from 'components/views/management/ManagementDeviceActivationView';
import ManagementDevicesView from 'components/views/management/ManagementDevicesView';
import ManagementSeniorCareDevicesView from 'components/views/management/ManagementSeniorCareDevicesView';
import ManagementSeniorCareUsersView from 'components/views/management/ManagementUsersView';
import MyOrganizationView from 'components/views/MyOrganizationView';
import NotFoundView from 'components/views/NotFoundView';
import OrganizationDetailsView from 'components/views/OrganizationDetailsView';
import OrganizationHomeView from 'components/views/OrganizationHomeView';
import OrganizationsView from 'components/views/OrganizationsView';
import { AdminDeviceStatisticsView } from 'components/views/statistics/AdminDeviceStatisticsView';
import { OrganizationDeviceStatisticsView } from 'components/views/statistics/OrganizationDeviceStatisticsView';
import { emailVerificationRoute } from 'components/views/user/EmailVerificationView';
import UsersView from 'components/views/UserView';
import { lazy, Suspense } from 'react';

const TanStackRouterDevtools =
  import.meta.env.MODE === 'production'
    ? () => null // Render nothing in production
    : () => {
        const DevTools = lazy(() =>
          import('@tanstack/router-devtools').then((res) => ({
            default: res.TanStackRouterDevtools,
          })),
        );

        return (
          <Suspense>
            <DevTools />
          </Suspense>
        );
      };

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const ScaffoldWithAuthAndOutlet = () => (
  <ScaffoldWithAuth>
    <Outlet />
  </ScaffoldWithAuth>
);

export const rootRoute = createRootRoute({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  ),
});

const indexRouter = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => (
    <ScaffoldWithAuth>
      <HomeView />
    </ScaffoldWithAuth>
  ),
});

const notFoundRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '*',
  component: NotFoundView,
});

export const managementRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'management',
  component: ScaffoldWithAuthAndOutlet,
});

export const myOrganizationRoute = createRoute({
  getParentRoute: () => managementRoute,
  path: '-',
  component: () => <MyOrganizationView routePrefix="/management/-" />,
});

export const organizationRoute = createRoute({
  getParentRoute: () => managementRoute,
  path: '$organization',
  component: OrganizationView,
});

export const organizationNotFoundRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: '*',
  component: NotFoundView,
});

const organizationHomeRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: '/',
  component: OrganizationHomeView,
});

export const deviceRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'devices/$deviceId',
  component: DeviceView,
});

const devicesRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'devices',
  component: ManagementDevicesView,
});

const seniorCareDevicesRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'senior-care-devices',
  component: ManagementSeniorCareDevicesView,
});

const organizationStatisticsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'statistics',
  component: OrganizationDeviceStatisticsView,
});

const seniorCareUsersRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'senior-care-users',
  component: ManagementSeniorCareUsersView,
});

const deviceActivationRequestsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'device-activation-requests',
  component: ManagementDeviceActivationView,
});

export const seniorCareIntegrationConfigurationsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'integration-configurations',
  component: SeniorCareIntegrationConfigsView,
});

const deviceConfigurationCreateRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'device-configurations/create',
  component: ManagementDeviceConfigurationCreateView,
});

export const deviceConfigurationEditRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'device-configurations/$deviceConfigurationId/edit',
  component: ManagementOrganizationDeviceConfigurationEditView,
});

const seniorCareDeviceConfigurationsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'senior-care-device-configurations',
  component: SeniorCareDeviceConfigsView,
});

const deviceConfigurationsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: 'device-configurations',
  component: ManagementDeviceConfigurationsView,
});

const adminRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'admin',
  component: AdminView,
});

const adminOrganizationsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'organizations',
  component: OrganizationsView,
});

export const adminOrganizationRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'organizations/$organizationId',
  component: OrganizationDetailsView,
});

const adminDevicesRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'devices',
  component: AdminDevicesView,
});

const adminSeniorCareUsersRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'senior-care-users',
  component: AdminSeniorCareUsersView,
});

const adminDeviceActivationRequestsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'device-activation-requests',
  component: AdminDeviceActivationView,
});

export const adminGlobalDeviceConfigurationRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'global-device-configurations/$globalDeviceConfigurationId/edit',
  component: AdminGlobalDeviceConfigurationEditView,
});

const adminGlobalDeviceConfigurationCreateRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'global-device-configurations/create',
  component: AdminGlobalDeviceConfigurationCreateView,
});

const adminGlobalDeviceConfigurationsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'global-device-configurations',
  component: AdminGlobalDeviceConfigurationView,
});

const adminOrganizationDeviceConfigurationCreateRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'organization-device-configurations/create',
  component: AdminOrganizationDeviceConfigurationCreateView,
});

export const adminOrganizationDeviceConfigurationEditRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'organization-device-configurations/$organizationDeviceConfigurationId/edit',
  component: AdminOrganizationDeviceConfigurationEditView,
});

const adminOrganizationDeviceConfigurationsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'organization-device-configurations',
  component: AdminOrganizationDeviceConfigurationView,
});

const contractsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'contracts',
  component: ContractsView,
});

const adminStatisticsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'statistics',
  component: AdminDeviceStatisticsView,
});

const adminUsersRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: 'users',
  component: UsersView,
});

const routeTree = rootRoute.addChildren([
  emailVerificationRoute,
  managementRoute.addChildren([
    myOrganizationRoute.addChildren([
      createRoute({
        getParentRoute: () => myOrganizationRoute,
        path: '*',
        component: () => <MyOrganizationView routePrefix="/management/-/" />,
      }),
    ]),
    organizationRoute.addChildren([
      organizationHomeRoute,
      devicesRoute,
      deviceActivationRequestsRoute,
      deviceConfigurationCreateRoute,
      deviceConfigurationEditRoute,
      deviceConfigurationsRoute,
      seniorCareIntegrationConfigurationsRoute,
      seniorCareDeviceConfigurationsRoute,
      seniorCareUsersRoute,
      deviceRoute,
      seniorCareDevicesRoute,
      organizationStatisticsRoute,
      organizationNotFoundRoute,
    ]),
  ]),
  adminRoute.addChildren([
    adminOrganizationsRoute,
    adminOrganizationRoute,
    adminUsersRoute,
    adminSeniorCareUsersRoute,
    adminDevicesRoute,
    adminDeviceActivationRequestsRoute,
    adminGlobalDeviceConfigurationRoute,
    adminGlobalDeviceConfigurationCreateRoute,
    adminGlobalDeviceConfigurationsRoute,
    adminOrganizationDeviceConfigurationEditRoute,
    adminOrganizationDeviceConfigurationCreateRoute,
    adminOrganizationDeviceConfigurationsRoute,
    contractsRoute,
    adminStatisticsRoute,
  ]),
  indexRouter,
  notFoundRoute,
]);

const router = createRouter({ routeTree });

Sentry.addIntegration(Sentry.tanstackRouterBrowserTracingIntegration(router));

export const useNullablePathOrganization = () =>
  useParams({ strict: false }).organization ?? null;

export const useManagementPathOrganization = () =>
  organizationRoute.useParams().organization;

export default router;

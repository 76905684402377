import { SettingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router';
import { Button, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { ItemType } from 'antd/lib/menu/interface';
import VoizeLogo from 'assets/voize_logo.svg';
import { FlexBox } from 'components/Helpers';
import SettingsModal from 'components/common/SettingsModal';
import ResourceIcons from 'components/common/resourceIcons';
import useIsAdmin from 'hooks/auth/useIsAdmin';
import useIsOrganizationView from 'hooks/auth/useIsOrganizationView';
import { useIsSeniorCareView } from 'hooks/auth/useIsSeniorCareView';
import { useOrganization } from 'hooks/auth/useOrganization';
import useUserOrg from 'hooks/auth/useUserOrg';
import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { BiBuildingHouse } from 'react-icons/bi';
import { FiChevronsLeft, FiLogIn, FiLogOut, FiMenu } from 'react-icons/fi';
import { isSidebarCollapsedAtom, isUserAdvancedAtom } from 'state/state';
import styled from 'styled-components';
import { useNullablePathOrganization } from '../../router';

interface DrawerContent {
  label: React.ReactNode;
  key: string;
  children?: DrawerContent[];
  icon?: React.ReactNode;
  showAdmin: boolean;
  showOrganization: boolean;
  isSeniorCare: boolean;
  advancedOnly?: boolean;
}

export const sidebarWidth = 340;

const Sidebar = () => {
  const isAdmin = useIsAdmin();
  const isOrgView = useIsOrganizationView();
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const organizationName = useOrganization()?.name ?? null;
  const paramOrg = useNullablePathOrganization();
  const userOrg = useUserOrg();
  const organization = paramOrg ?? userOrg;

  const [isUserAdvanced] = useAtom(isUserAdvancedAtom);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useAtom(
    isSidebarCollapsedAtom,
  );
  const isSeniorCareView = useIsSeniorCareView();

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const drawerContent: DrawerContent[] = useMemo(
    () => [
      {
        label: 'Geräteverwaltung',
        key: 'devices-admin',
        icon: <ResourceIcons.Device />,
        children: [
          {
            label: (
              <Link to="/admin/global-device-configurations">
                Globale Gerätekonfigurationen
              </Link>
            ),
            icon: <ResourceIcons.GlobalDeviceConfiguration />,
            key: 'admin-global-device-configurations',
            target: '/admin/global-device-configurations',
            showAdmin: true,
            showOrganization: false,
            isSeniorCare: false,
          },
          {
            label: (
              <Link to="/admin/organization-device-configurations">
                Organisations-Gerätekonfigurationen
              </Link>
            ),
            icon: <ResourceIcons.OrganizationDeviceConfiguration />,
            key: 'admin-organization-device-configurations',
            showAdmin: true,
            showOrganization: false,
            isSeniorCare: false,
          },
          {
            label: (
              <Link to="/admin/devices">Geräte u. Aktivierungsanfragen</Link>
            ),
            key: 'admin-devices',
            icon: <ResourceIcons.Device />,
            showAdmin: true,
            showOrganization: false,
            isSeniorCare: false,
          },
          {
            label: (
              <Link to="/admin/device-activation-requests">
                Aktivierungsanfragen (deprecated)
              </Link>
            ),
            icon: <ResourceIcons.ActivationRequest />,
            key: 'admin-device-activation-requests',
            showAdmin: true,
            showOrganization: false,
            isSeniorCare: false,
          },
        ],
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: false,
      },
      {
        label: (
          <Link to="/management/$organization" params={{ organization }}>
            Startseite
          </Link>
        ),
        icon: <ResourceIcons.Home />,
        key: 'management-home',
        showAdmin: false,
        showOrganization: true,
        isSeniorCare: false,
      },
      {
        label: 'Allgemein',
        key: 'general',
        icon: <ResourceIcons.Device />,
        children: [
          {
            label: (
              <Link
                to="/management/$organization/devices"
                params={{ organization }}
              >
                Geräte
              </Link>
            ),
            icon: <ResourceIcons.Device />,
            key: 'management-devices-list',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: false,
            advancedOnly: true,
          },
          {
            label: (
              <Link
                to="/management/$organization/device-configurations"
                params={{ organization }}
              >
                Gerätekonfigurationen
              </Link>
            ),
            icon: <ResourceIcons.DeviceConfiguration />,
            key: 'management-device-configurations',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: false,
            advancedOnly: true,
          },
          {
            icon: <ResourceIcons.ActivationRequest />,
            label: (
              <Link
                to="/management/$organization/device-activation-requests"
                params={{ organization }}
              >
                Aktivierungsanfragen
              </Link>
            ),
            key: 'management-device-activation-requests',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: false,
            advancedOnly: true,
          },
          {
            icon: <ResourceIcons.Statistics />,
            label: (
              <Link
                to="/management/$organization/statistics"
                params={{ organization }}
              >
                Statistiken
              </Link>
            ),
            key: 'management-statistics',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: false,
            advancedOnly: true,
          },
        ],
        showAdmin: false,
        showOrganization: true,
        isSeniorCare: false,
      },
      {
        label: 'Pflege App',
        key: 'senior-care-app',
        icon: <ResourceIcons.Device />,
        children: [
          {
            label: (
              <Link
                to="/management/$organization/senior-care-devices"
                params={{ organization }}
              >
                Geräte
              </Link>
            ),
            icon: <ResourceIcons.Device />,
            key: 'management-senior-care-devices-list',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: true,
            advancedOnly: false,
          },
          {
            label: (
              <Link
                to="/management/$organization/integration-configurations"
                params={{ organization }}
              >
                Schnittstellenverwaltung
              </Link>
            ),
            icon: <ResourceIcons.Integration />,
            key: 'management-integration-configurations',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: true,
          },
          {
            label: (
              <Link
                to="/management/$organization/senior-care-device-configurations"
                params={{ organization }}
              >
                Gerätekonfigurationen
              </Link>
            ),
            icon: <ResourceIcons.DeviceConfiguration />,
            key: 'management-senior-care-configurations',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: true,
          },
          {
            label: (
              <Link
                to="/management/$organization/senior-care-users"
                params={{ organization }}
              >
                Benutzer
              </Link>
            ),
            icon: <ResourceIcons.User />,
            key: 'management-senior-care-users-list',
            showAdmin: false,
            showOrganization: true,
            isSeniorCare: true,
            advancedOnly: false,
          },
        ],
        showAdmin: false,
        showOrganization: true,
        isSeniorCare: true,
      },
      {
        label: <Link to="/admin/users">Benutzer</Link>,
        key: 'admin-users-group',
        icon: <ResourceIcons.User />,
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: false,
      },
      {
        label: <Link to="/admin/senior-care-users">Pflegebenutzer</Link>,
        icon: <ResourceIcons.User />,
        key: 'admin-senior-care-users-list',
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: true,
      },
      {
        label: <Link to="/admin/organizations">Organisationen</Link>,
        key: 'admin-organizations-group',
        icon: <ResourceIcons.Organization />,
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: false,
      },
      {
        label: <Link to="/admin/contracts">Zustimmungen</Link>,
        key: 'contracts',
        icon: <ResourceIcons.Contract />,
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: false,
      },
      {
        label: <Link to="/admin/statistics">Statistiken</Link>,
        key: 'statistics',
        icon: <ResourceIcons.Statistics />,
        showAdmin: true,
        showOrganization: false,
        isSeniorCare: false,
      },
    ],
    [organization],
  );

  const transformDrawerContent = useCallback(
    (content: DrawerContent[]): ItemType[] => {
      return content
        .map((item) => {
          if ((!isAdmin || isOrgView) && item.showAdmin) {
            return null;
          }

          if (!isOrgView && isAdmin && item.showOrganization) {
            return null;
          }

          if (isOrgView && !isSeniorCareView && item.isSeniorCare) {
            return null;
          }

          if (isSeniorCareView && item.advancedOnly && !isUserAdvanced) {
            return null;
          }

          const children = item.children
            ? transformDrawerContent(item.children)
            : undefined;

          if (children?.length === 0) {
            return null;
          }

          return {
            key: item.key,
            label: item.label,
            icon: item.icon,
            children: children,
          };
        })
        .filter((item) => item !== null);
    },
    [isAdmin, isOrgView, isSeniorCareView, isUserAdvanced],
  );

  const items = useMemo(
    () => transformDrawerContent(drawerContent),
    [transformDrawerContent, drawerContent],
  );

  return (
    <>
      {isSidebarCollapsed && (
        <Button
          type="text"
          style={{
            fontSize: 23,
            color: 'hsl(200, 10%, 40%)',
            marginTop: 30,
            marginLeft: 20,
          }}
          icon={<FiMenu />}
          onClick={() => setIsSidebarCollapsed(false)}
        />
      )}
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={isSidebarCollapsed}
        breakpoint="lg"
        collapsedWidth="0"
        width={sidebarWidth}
        style={{
          position: 'fixed',
          height: '100%',
          zIndex: 100,
        }}
      >
        <SettingsModal
          isOpen={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
        />
        <FlexBox
          direction="column"
          withgap
          style={{
            padding: '0 10px',
            height: '100%',
            borderRight: '1px solid hsl(200, 10%, 90%)',
          }}
        >
          <FlexBox
            direction="column"
            withgap
            style={{
              borderBottom: '1px solid hsl(200, 10%, 90%)',
              padding: '20px 0 20px',
            }}
          >
            <FlexBox
              direction="row"
              justifycontent="space-between"
              alignitems="center"
              style={{ padding: '0 5px' }}
            >
              <Link to="/" style={{ color: 'black' }}>
                <FlexBox alignitems="center" gap={10} withgap>
                  <img src={VoizeLogo} />
                  <AdminTag>Admin</AdminTag>
                </FlexBox>
              </Link>
              <Button
                type="text"
                style={{ fontSize: 23, color: 'hsl(200, 10%, 70%)' }}
                icon={<FiChevronsLeft />}
                onClick={() => setIsSidebarCollapsed(true)}
              />
            </FlexBox>
            {organizationName !== null && (
              <Organization style={{ marginTop: '12px' }}>
                <BiBuildingHouse />
                <span>{organizationName}</span>
              </Organization>
            )}
          </FlexBox>
          <Menu
            theme="light"
            mode="inline"
            items={items}
            defaultOpenKeys={drawerContent.map(({ key }) => key)}
          />
          <FlexBox
            direction="column"
            withgap
            style={{
              marginTop: '80px',
              borderTop: '1px solid hsl(200, 10%, 90%)',
              padding: '20px 0',
            }}
          >
            <SettingsButton
              icon={<SettingOutlined />}
              type="text"
              onClick={() => setIsSettingsModalOpen(true)}
            >
              Einstellungen
            </SettingsButton>
            <FlexBox
              alignitems="center"
              withgap
              style={{ padding: '0 10px' }}
              justifycontent="space-between"
            >
              <FlexBox withgap gap={9} alignitems="center">
                <div
                  style={{
                    backgroundColor: 'hsl(200, 10%, 70%)',
                    width: 14,
                    height: 14,
                    borderRadius: 30,
                  }}
                />
                <span style={{ fontWeight: 'bold' }}>{user?.name}</span>
              </FlexBox>
              {isAuthenticated ? (
                <Button
                  type="text"
                  icon={<FiLogOut />}
                  style={{ color: 'hsl(200, 10%, 50%)' }}
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  size="large"
                />
              ) : (
                <Button
                  type="text"
                  icon={<FiLogIn />}
                  style={{ color: 'hsl(200, 10%, 50%)' }}
                  onClick={() => loginWithRedirect()}
                  size="large"
                />
              )}
            </FlexBox>
          </FlexBox>
          {import.meta.env.VITE_VERSION && (
            <FlexBox
              style={{
                flex: 1,
                padding: '10px',
                fontFamily: 'monospace',
                alignItems: 'flex-end',
              }}
            >
              <span>{import.meta.env.VITE_VERSION}</span>
            </FlexBox>
          )}
        </FlexBox>
      </Sider>
    </>
  );
};

const SettingsButton = styled(Button)`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
`;

const AdminTag = styled.div`
  font-family: monospace;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: hsl(200, 10%, 90%);
`;

const Organization = styled.div`
  font-size: 18px;
  padding: 0 10px;
  align-self: flex-start;
  font-weight: 600;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 4px;
  color: hsl(200, 10%, 30%);
`;

export default Sidebar;

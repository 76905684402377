import { Card, Col, Row, Select, Space, Switch } from 'antd';
import styled from 'styled-components';
import {
  DEV_ORGANIZATIONS,
  FilterOptions,
  FilterSettings,
  FilterSettingsHandlers,
} from '../metadata/utils';

export interface Props {
  settings: FilterSettings;
  handlers: FilterSettingsHandlers;
  options: FilterOptions;
  showSourceFilter?: boolean;
  showOrganizationFilter?: boolean;
  showActiveFilter?: boolean;
  showIndustryFilter?: boolean;
  showDevOrganizationsFilter?: boolean;
}

export const DeviceFilters: React.FC<Props> = ({
  settings,
  handlers,
  options,
  showSourceFilter = false,
  showOrganizationFilter = false,
  showActiveFilter = false,
  showIndustryFilter = false,
  showDevOrganizationsFilter = false,
}) => {
  return (
    <FilterContainer>
      {showIndustryFilter && (
        <FilterRow>
          <Col flex="200px" className="flex items-center">
            <span>Industry</span>
          </Col>
          <Col flex="auto">
            <Select
              className="w-full"
              placeholder="Filter by Industry"
              mode="multiple"
              allowClear
              showSearch
              value={settings.selectedIndustries}
              onChange={handlers.setSelectedIndustries}
              options={options.industries.map((industry) => ({
                label: industry,
                value: industry,
              }))}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </FilterRow>
      )}

      {showSourceFilter && (
        <FilterRow>
          <Col flex="200px" className="flex items-center">
            <span>Source</span>
          </Col>
          <Col flex="auto">
            <Select
              className="w-full"
              placeholder="Filter by Source"
              mode="multiple"
              allowClear
              showSearch
              value={settings.selectedSources}
              onChange={handlers.setSelectedSources}
              options={options.sources.map((source) => ({
                label: source,
                value: source,
              }))}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </FilterRow>
      )}

      {showOrganizationFilter && (
        <FilterRow>
          <Col flex="200px" className="flex items-center">
            <span>Organization</span>
          </Col>
          <Col flex="auto">
            <Select
              className="w-full"
              placeholder="Filter by Organization"
              mode="multiple"
              allowClear
              showSearch
              value={settings.selectedOrganizations}
              onChange={handlers.setSelectedOrganizations}
              options={options.organizations
                .filter(
                  (org) =>
                    !settings.hideDevOrganizations ||
                    !DEV_ORGANIZATIONS.includes(org),
                )
                .map((org) => ({
                  label: org,
                  value: org,
                }))}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        </FilterRow>
      )}

      {showDevOrganizationsFilter && (
        <FilterRow>
          <Col flex="200px" className="flex items-center">
            <span>Hide Development Organizations</span>
          </Col>
          <Col flex="auto">
            <Space align="center">
              <Switch
                checked={settings.hideDevOrganizations}
                onChange={handlers.setHideDevOrganizations}
              />
            </Space>
          </Col>
        </FilterRow>
      )}

      {showActiveFilter && (
        <FilterRow>
          <Col flex="200px" className="flex items-center">
            <span>Show only devices active in last 30 days</span>
          </Col>
          <Col flex="auto">
            <Space align="center">
              <Switch
                checked={settings.showOnlyActive}
                onChange={handlers.setShowOnlyActive}
              />
            </Space>
          </Col>
        </FilterRow>
      )}
    </FilterContainer>
  );
};

const FilterContainer = styled(Card)`
  max-width: 32rem;
`;

const FilterRow = styled(Row)`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

import { FilterOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FlexBox, FlexOne } from 'components/Helpers';
import { ManagementDevice } from 'interfaces/device';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  DEV_ORGANIZATIONS,
  FilterSettings,
  FilterSettingsHandlers,
  renderFilterSettings,
} from '../metadata/utils';
import { DeviceFilters } from './DeviceFilters';

export interface DeviceFilteringConfig {
  showSourceFilter?: boolean;
  showOrganizationFilter?: boolean;
  showActiveFilter?: boolean;
  showIndustryFilter?: boolean;
  showDevOrganizationsFilter?: boolean;
}

export const useFilteredDevices = (
  devices: ManagementDevice[],
  config: DeviceFilteringConfig,
  initialShowOnlyActive = true,
  initialHideDevOrganizations = false,
) => {
  const [filterSettings, setFilterSettings] = useState<FilterSettings>({
    selectedSources: [],
    selectedOrganizations: [],
    selectedIndustries: [],
    showOnlyActive: initialShowOnlyActive,
    hideDevOrganizations: initialHideDevOrganizations,
  });

  const filterHandlers: FilterSettingsHandlers = {
    setSelectedSources: (sources) =>
      setFilterSettings((prev) => ({ ...prev, selectedSources: sources })),
    setSelectedOrganizations: (orgs) =>
      setFilterSettings((prev) => ({ ...prev, selectedOrganizations: orgs })),
    setSelectedIndustries: (industries) =>
      setFilterSettings((prev) => ({
        ...prev,
        selectedIndustries: industries,
      })),
    setShowOnlyActive: (active) =>
      setFilterSettings((prev) => ({ ...prev, showOnlyActive: active })),
    setHideDevOrganizations: (hide) =>
      setFilterSettings((prev) => ({ ...prev, hideDevOrganizations: hide })),
  };

  const filterOptions = useMemo(() => {
    const sources = new Set<string>();
    const organizations = new Set<string>();
    const industries = new Set<string>();

    devices.forEach((device) => {
      if (device.metadata?.['voize/source']) {
        sources.add(device.metadata['voize/source']);
      }
      if (device.organization) {
        organizations.add(device.organization);
      }
      if (device.metadata?.['voize/industry']) {
        industries.add(device.metadata['voize/industry']);
      }
    });

    return {
      sources: Array.from(sources),
      organizations: Array.from(organizations),
      industries: Array.from(industries),
    };
  }, [devices]);

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      if (filterSettings.showOnlyActive) {
        const lastSeenAt = device.lastSeenAt
          ? new Date(device.lastSeenAt)
          : null;
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        if (!lastSeenAt || lastSeenAt < thirtyDaysAgo) {
          return false;
        }
      }

      if (
        filterSettings.selectedSources.length > 0 &&
        !filterSettings.selectedSources.includes(
          device.metadata?.['voize/source'] || '',
        )
      ) {
        return false;
      }

      const org = device.organization || '';
      if (
        filterSettings.hideDevOrganizations &&
        DEV_ORGANIZATIONS.includes(org)
      ) {
        return false;
      }
      if (
        filterSettings.selectedOrganizations.length > 0 &&
        !filterSettings.selectedOrganizations.includes(org)
      ) {
        return false;
      }

      if (
        filterSettings.selectedIndustries.length > 0 &&
        !filterSettings.selectedIndustries.includes(
          device.metadata?.['voize/industry'] || '',
        )
      ) {
        return false;
      }

      return true;
    });
  }, [devices, filterSettings]);

  const controls =
    config.showActiveFilter ||
    config.showSourceFilter ||
    config.showOrganizationFilter ||
    config.showIndustryFilter ||
    config.showDevOrganizationsFilter ? (
      <DeviceFilters
        settings={filterSettings}
        handlers={filterHandlers}
        options={filterOptions}
        showSourceFilter={config.showSourceFilter}
        showOrganizationFilter={config.showOrganizationFilter}
        showActiveFilter={config.showActiveFilter}
        showIndustryFilter={config.showIndustryFilter}
        showDevOrganizationsFilter={config.showDevOrganizationsFilter}
      />
    ) : null;

  const renderedFilters = renderFilterSettings(filterSettings);
  const stats = (
    <FlexBox direction="row">
      <StatsContainer>
        <strong>{filteredDevices.length}</strong> Geräte
        {renderedFilters.length > 0 && (
          <FilteredText>({renderedFilters})</FilteredText>
        )}
      </StatsContainer>
      <FlexOne />
    </FlexBox>
  );

  const isAnyFilterEnabled =
    config.showActiveFilter ||
    config.showSourceFilter ||
    config.showOrganizationFilter ||
    config.showIndustryFilter ||
    config.showDevOrganizationsFilter;
  const ui = (
    <FlexBox direction="column" withgap gap={8}>
      <Typography.Title level={4}>
        <FlexBox alignitems="center" withgap>
          <FilterOutlined />
          {isAnyFilterEnabled ? <span>Filter</span> : <span>Übersicht</span>}
        </FlexBox>
      </Typography.Title>
      {controls}
      {stats}
    </FlexBox>
  );

  return { filteredDevices, ui };
};

const StatsContainer = styled.div`
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;

  strong {
    color: #1890ff;
    font-size: 1.25rem;
  }
`;

const FilteredText = styled.span`
  margin-left: 0.5rem;
  color: #6b7280;
  font-size: 0.9rem;
  font-style: italic;
`;

import { Input, Tooltip } from 'antd';
import { FlexBox } from 'components/Helpers';
import { Duration, DurationLikeObject } from 'luxon';
import styled from 'styled-components';

interface Props {
  /**
   * Duration in ISO 8601 format
   */
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

/**
 * DurationTimePicker is a component that allows the user to pick a duration in days, hours, minutes and seconds.
 * Only time parts are supported, which can be converted to a well-defined number of seconds
 */
const DurationTimePicker = ({ value, onChange, isDisabled = false }: Props) => {
  const duration = Duration.fromISO(value);

  const parts: {
    label: string;
    tooltip: string;
    part: keyof DurationLikeObject;
    value: number;
  }[] = [
    { label: 'D', tooltip: 'Days', part: 'days', value: duration.days },
    { label: 'h', tooltip: 'Hours', part: 'hours', value: duration.hours },
    {
      label: 'm',
      tooltip: 'Minutes',
      part: 'minutes',
      value: duration.minutes,
    },
    {
      label: 's',
      tooltip: 'Seconds',
      part: 'seconds',
      value: duration.seconds,
    },
  ];

  return (
    <FlexBox withgap alignitems="center">
      {parts.map(({ label, tooltip, part, value }) => (
        <Tooltip title={tooltip} key={part}>
          <StyledInput
            placeholder="0"
            type="number"
            addonBefore={label}
            onChange={(e) => {
              onChange(
                Duration.fromObject({
                  ...duration.toObject(),
                  [part]: parseInt(e.target.value || '0'),
                }).toISO(),
              );
            }}
            value={value}
            disabled={isDisabled}
          />
        </Tooltip>
      ))}
    </FlexBox>
  );
};

const StyledInput = styled(Input)`
  width: 76px;
  text-align: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default DurationTimePicker;

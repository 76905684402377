import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, message, Popconfirm } from 'antd';
import { useAdminOrganizationApi } from 'api/useAdminOrganizationApi';
import { Organization } from 'interfaces/organization';
import { useState } from 'react';
import OrganizationModal from './OrganizationModal';

interface Props {
  organization: Organization;
  onDeleted?: () => void;
}
const OrganizationControls: React.FC<Props> = ({ organization, onDeleted }) => {
  const queryClient = useQueryClient();
  const { deleteOrganization } = useAdminOrganizationApi();

  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const { mutateAsync: removeOrg } = useMutation({
    mutationFn: deleteOrganization,
    onSuccess: () => {
      onDeleted?.();
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
      queryClient.invalidateQueries({
        queryKey: ['organizations', organization.id],
      });
      message.success('Organisation wurde gelöscht.');
    },
    onError: (error) => {
      message.error(`Error deleting organization: ${error.message}`);
    },
  });

  return (
    <>
      <OrganizationModal
        open={isEditEnabled}
        onClose={() => setIsEditEnabled(false)}
        organization={organization}
        onEdited={() => {
          queryClient.invalidateQueries({ queryKey: ['organizations'] });
          queryClient.invalidateQueries({
            queryKey: ['organizations', organization.id],
          });
        }}
      />
      <Button onClick={() => setIsEditEnabled(true)} icon={<EditOutlined />} />
      <Popconfirm
        title="Are you sure you want to delete this organization?"
        onConfirm={() => removeOrg(organization.id)}
        okText="Yes"
        cancelText="No"
      >
        <Button danger onClick={() => {}} icon={<DeleteOutlined />} />
      </Popconfirm>
    </>
  );
};

export default OrganizationControls;

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Switch,
} from 'antd';
import DurationTimePicker from 'components/common/DurationTimePicker';
import AuthMethodPicker from 'components/device-configuration/features/user-auth-manager-oidc/AuthMethodPicker';
import { FlexBox } from 'components/Helpers';
import Joi from 'joi';
import { useMemo } from 'react';
import { validateJsonProperty, validateOrThrow } from 'utlis/validationUtil';

interface Props {
  initialProperties?: Record<string, string>;
  onSubmit: (config: Record<string, string>) => void;
  onEditDone?: () => void;
}

export enum AuthMethod {
  BASIC = 'Basic',
  POST = 'Post',
}

export const oidcConfigDeviceProperty =
  'USER_AUTHENTICATION_MANAGER_OIDC_CONFIG';

interface FormData {
  clientAuthMethod?: AuthMethod;
  clientId?: string;
  clientSecret?: string;
  discoveryUri?: string;
  scopes?: string[];
  isRefreshTokenRotationEnabled?: boolean;
  refreshTokenExpirationDuration?: string;
  endSessionOnLogout?: boolean;
  useRefreshTokens?: boolean;
  forceReAuthenticate?: boolean;
  skipEmailVerifiedCheck?: boolean;
  responseMode?: string | null;
  additionalAuthorizationParameters?: { key: string; value: string }[] | null;
}

export const OIDCConfigSchema = Joi.object<FormData>({
  clientId: Joi.string().required(),
  clientSecret: Joi.string().allow(null).empty('').default(null),
  clientAuthMethod: Joi.string()
    .valid(...Object.values(AuthMethod))
    .when('clientSecret', {
      is: Joi.string().min(1),
      then: Joi.when('clientSecret', {
        is: Joi.exist(),
        then: Joi.required(),
        otherwise: Joi.optional().allow(null),
      }),
      otherwise: Joi.optional().allow(null),
    }),
  discoveryUri: Joi.string().uri().required(),
  scopes: Joi.array().items(Joi.string()).required(),
  isRefreshTokenRotationEnabled: Joi.boolean().required(),
  refreshTokenExpirationDuration: Joi.string().allow('').optional().allow(null),
  endSessionOnLogout: Joi.boolean().required(),
  skipEmailVerifiedCheck: Joi.boolean(),
  useRefreshTokens: Joi.boolean(),
  forceReAuthenticate: Joi.boolean(),
  responseMode: Joi.string().allow('').optional().allow(null),
  additionalAuthorizationParameters: Joi.object()
    .pattern(Joi.string(), Joi.string())
    .allow(null),
});

const UserManagerOIDCConfigForm: React.FC<Props> = ({
  initialProperties = {},
  onSubmit,
  onEditDone,
}) => {
  const formId = 'user-manager-oidc-config-form';
  const [form] = Form.useForm<FormData>();
  const useRefreshTokens = Form.useWatch('useRefreshTokens', form);

  const [oidcConfig] = useMemo(
    () =>
      validateJsonProperty(
        initialProperties[oidcConfigDeviceProperty],
        OIDCConfigSchema,
      ),
    [initialProperties],
  );

  return (
    <Form<FormData>
      id={formId}
      form={form}
      labelAlign="left"
      labelCol={{ span: 6 }}
      labelWrap={true}
      onFinish={(formData) => {
        const updatedOidcConfig = validateOrThrow(
          {
            ...formData,
            additionalAuthorizationParameters:
              formData.additionalAuthorizationParameters !== null &&
              formData.additionalAuthorizationParameters !== undefined
                ? Object.fromEntries(
                    formData.additionalAuthorizationParameters.map((param) => [
                      param.key,
                      param.value,
                    ]),
                  )
                : formData.additionalAuthorizationParameters,
          },
          OIDCConfigSchema,
        );

        const currentOidcConfig = oidcConfig
          ? oidcConfig
          : {
              clientId: '',
              clientSecret: null,
              clientAuthMethod: null,
              discoveryUri: '',
              scopes: [],
              isRefreshTokenRotationEnabled: false,
              refreshTokenExpirationDuration: null,
              endSessionOnLogout: false,
              skipEmailVerifiedCheck: false,
              useRefreshTokens: false,
              forceReAuthenticate: false,
              responseMode: null,
              additionalAuthorizationParameters: null,
            };

        onSubmit({
          [oidcConfigDeviceProperty]: JSON.stringify({
            ...currentOidcConfig,
            ...updatedOidcConfig,
          }),
        });
        onEditDone?.();
      }}
      initialValues={{
        ...oidcConfig,
        additionalAuthorizationParameters: Object.entries(
          oidcConfig?.additionalAuthorizationParameters ?? {},
        ).map(([key, value]) => ({ key, value })),
      }}
    >
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => (
          <>
            <Form.Item
              label="Client ID"
              name="clientId"
              tooltip="Client ID of the application."
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie die Client ID ein.',
                },
              ]}
              initialValue={oidcConfig?.clientId ?? ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              name="clientSecret"
              tooltip="Client secret of the application. This is only required for the authorization code flow without PKCE."
              initialValue={oidcConfig?.clientSecret ?? ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Client Auth Method"
              name="clientAuthMethod"
              tooltip="The method to use for client authentication. This is only required if the client secret is used. Only effective on Android."
              rules={[
                {
                  required: getFieldValue('clientSecret') ? true : false,
                  message: 'Bitte geben Sie die Client Auth Methode ein.',
                },
              ]}
              initialValue={oidcConfig?.clientAuthMethod ?? null}
            >
              <AuthMethodPicker />
            </Form.Item>
            <Form.Item
              label="Discovery URI"
              name="discoveryUri"
              tooltip="The discovery URI of the OpenID Connect provider."
              rules={[
                {
                  required: true,
                  type: 'url',
                  message: 'Bitte geben Sie eine gültige Discovery URI ein.',
                },
              ]}
              initialValue={oidcConfig?.discoveryUri ?? ''}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Scopes"
              name="scopes"
              tooltip={
                'The list of scopes to request. If refresh tokens are requested, this must also include "offline_access".'
              }
              rules={[
                {
                  required: true,
                  type: 'array',
                  message: 'Bitte geben Sie mindestens einen Scope ein.',
                },
              ]}
              initialValue={oidcConfig?.scopes ?? []}
            >
              <Select mode="tags" />
            </Form.Item>

            <Form.Item
              label="End Session on Logout"
              name="endSessionOnLogout"
              tooltip="If true, the user will be logged out of the identity provider when the user logs out of the app."
              initialValue={oidcConfig?.endSessionOnLogout ?? false}
            >
              <Switch />
            </Form.Item>

            <Divider>Refresh Token</Divider>

            <Form.Item name="useRefreshTokens" noStyle />
            <Form.Item
              label="Use Refresh Tokens"
              name="useRefreshTokens"
              tooltip="If true, refresh tokens will be used to refresh the access token."
              initialValue={oidcConfig?.useRefreshTokens ?? false}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              name="isRefreshTokenRotationEnabled"
              noStyle
              initialValue={oidcConfig?.isRefreshTokenRotationEnabled ?? false}
            />
            {useRefreshTokens && (
              <>
                <Form.Item
                  label="Refresh Token Rotation"
                  tooltip="If true, the refresh token will be rotated on every token request."
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.isRefreshTokenRotationEnabled !==
                    currentValues.isRefreshTokenRotationEnabled
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue(
                      'isRefreshTokenRotationEnabled',
                    );

                    return (
                      <Switch
                        checked={value}
                        onChange={(value) =>
                          setFieldsValue({
                            isRefreshTokenRotationEnabled: value,
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
                <Form.Item name="refreshTokenExpirationDuration" noStyle />
                <Form.Item
                  label="Refresh Token Expiration Duration"
                  tooltip="The duration after which the refresh token expires. If null, the refresh token will never expire."
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.refreshTokenExpirationDuration !==
                    currentValues.refreshTokenExpirationDuration
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value =
                      getFieldValue('refreshTokenExpirationDuration') ?? null;
                    const defaultDuration = 'P1D';

                    return (
                      <Radio.Group
                        value={value === null}
                        onChange={(e) => {
                          setFieldsValue({
                            refreshTokenExpirationDuration: e.target.value
                              ? null
                              : defaultDuration,
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={true}>Never expires</Radio>
                          <Radio value={false}>
                            <DurationTimePicker
                              value={value ?? defaultDuration}
                              onChange={(value) => {
                                setFieldsValue({
                                  refreshTokenExpirationDuration: value,
                                });
                              }}
                              isDisabled={value === null}
                            />
                          </Radio>
                        </Space>
                      </Radio.Group>
                    );
                  }}
                </Form.Item>
              </>
            )}

            <Divider>Advanced Settings</Divider>

            <Form.Item
              label="Skip E-Mail Verified Check"
              name="skipEmailVerifiedCheck"
              tooltip="If true, the `email_verified` claim of the ID token will not be checked."
              initialValue={!!oidcConfig?.skipEmailVerifiedCheck}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Force Re-Authenticate"
              name="forceReAuthenticate"
              tooltip="If true, the user will need to re-authenticate even if there is a session in the authorization server."
              initialValue={!!oidcConfig?.forceReAuthenticate}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Additional Authorization Parameters"
              tooltip="Additional parameters to add to the authorization request."
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.additionalAuthorizationParameters !==
                currentValues.additionalAuthorizationParameters
              }
            >
              {({ getFieldValue, setFieldsValue }) => {
                const value =
                  getFieldValue('additionalAuthorizationParameters') ?? null;

                return (
                  <FlexBox withgap>
                    <Checkbox
                      value={value !== null}
                      defaultChecked={value !== null}
                      onChange={(e) => {
                        setFieldsValue({
                          additionalAuthorizationParameters: e.target.checked
                            ? []
                            : null,
                        });
                      }}
                    />
                    {value !== null && (
                      <Form.List name="additionalAuthorizationParameters">
                        {(fields, { add, remove }) => (
                          <FlexBox direction="column" withgap>
                            {fields.map((field) => (
                              <FlexBox key={field.key} withgap>
                                <Form.Item
                                  noStyle
                                  name={[field.name, 'key']}
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Key" />
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  name={[field.name, 'value']}
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Value" />
                                </Form.Item>
                                <Button
                                  onClick={() => remove(field.name)}
                                  icon={<CloseOutlined />}
                                  type="text"
                                />
                              </FlexBox>
                            ))}
                            <Button
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add Paramter
                            </Button>
                          </FlexBox>
                        )}
                      </Form.List>
                    )}
                  </FlexBox>
                );
              }}
            </Form.Item>

            <Form.Item
              label="Response Mode"
              tooltip="Specifies the response mode to be used for returning authorization response parameters from the authorization endpoint."
              initialValue={oidcConfig?.responseMode ?? null}
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.responseMode !== currentValues.responseMode
              }
            >
              {({ getFieldValue, setFieldsValue }) => {
                const value = getFieldValue('responseMode') ?? null;

                return (
                  <FlexBox withgap>
                    <Checkbox
                      value={value !== null}
                      defaultChecked={value !== null}
                      onChange={(e) => {
                        setFieldsValue({
                          responseMode: e.target.checked ? '' : null,
                        });
                      }}
                    />
                    {value !== null && (
                      <Form.Item name="responseMode" noStyle>
                        <Input placeholder="Response mode" />
                      </Form.Item>
                    )}
                  </FlexBox>
                );
              }}
            </Form.Item>
          </>
        )}
      </Form.Item>
      {onEditDone && (
        <Button
          type="primary"
          key="submit"
          form={formId}
          onClick={() => form.submit()}
        >
          Speichern
        </Button>
      )}
    </Form>
  );
};

export default UserManagerOIDCConfigForm;

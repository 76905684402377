import {
  BarChartOutlined,
  DownloadOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';
import { downloadCSV } from './utils';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884d8',
  '#82ca9d',
];

interface Props {
  property: string;
  distribution: Record<string, number>;
}

/**
 * Card that supports:
 * - Pie chart
 * - Table
 * - Download CSV
 * - Toggle view between pie chart and table
 */
export const StatisticsCard: React.FC<Props> = ({ property, distribution }) => {
  const [showTable, setShowTable] = useState(false);

  return (
    <StatCard>
      <CardHeader>
        <CardTitle>{property}</CardTitle>
        <Space>
          <Button
            size="small"
            icon={<DownloadOutlined />}
            onClick={() => downloadCSV(property, distribution)}
          />
          <Button
            size="small"
            icon={showTable ? <BarChartOutlined /> : <TableOutlined />}
            onClick={() => setShowTable(!showTable)}
          />
        </Space>
      </CardHeader>

      {showTable ? (
        <TableContainer>
          <Table
            dataSource={Object.entries(distribution).map(([name, value]) => ({
              key: name,
              name,
              value,
              percentage:
                (
                  (value /
                    Object.values(distribution).reduce((a, b) => a + b, 0)) *
                  100
                ).toFixed(1) + '%',
            }))}
            columns={[
              { title: 'Name', dataIndex: 'name' },
              {
                title: 'Count',
                dataIndex: 'value',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.value - b.value,
              },
              { title: 'Percentage', dataIndex: 'percentage' },
            ]}
            pagination={false}
            size="small"
            scroll={{ y: 240 }}
          />
        </TableContainer>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={Object.entries(distribution).map(([name, value]) => ({
                name,
                value,
              }))}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) =>
                `${name} (${(percent * 100).toFixed(0)}%)`
              }
            >
              {Object.entries(distribution).map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              layout="horizontal"
              wrapperStyle={{
                maxHeight: '64px',
                overflowY: 'auto',
                paddingBottom: '20px',
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </StatCard>
  );
};

const StatCard = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  background-color: white;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`;

const TableContainer = styled.div`
  height: 300px;
`;
